import React from 'react';
import Menu from './Menu';
import '../css/Projects.css'

export default function Projects(){
    return(
        <div className="projects">
            <Menu/>
            <div id="projectsContent">
            <p>The demo-able projects that I have are located on Expo. 
                I am working on making others that I have more accessible, 
                and will also in the near future be making how-to resources 
                (probably YouTube videos) for my more code-based projects.</p>
            <h4><a 
                id="expoLink"
                href="https://expo.io/@23mwest" 
                target="_blank" 
                rel="noopener noreferrer">Expo Profile</a> </h4>
                <p>To demo a project:</p>
                <p>1. Download the Expo Client <a href="https://expo.io/tools#client">here</a></p>
                <p>2. In your browser, click "Open" next to the project from the link above that you want to see</p>
                <p>3. Scan the QR code that pops up with your phone's camera</p>
            </div>
        
        </div>
    )
}