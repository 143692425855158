import React, { Component } from 'react'
import Resume from '../pdfs/MichaelJWest-Resume.pdf';
import Menu from './Menu';
import Slider from 'react-slick';
import Stella from '../assets/pics/home/stella.JPG'
import Rocks from '../assets/pics/home/rocks.JPG';
import '../css/Home.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Home extends Component {
    render() {
        var sliderSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        return (
            <div className="home">
                <Menu/>
                <div className="App">
                <p id="aboutMe">I'm a Computer Engineering graduate from Pitt, currently specializing in Full Stack Software Development and Mobile Design.
                    My favorite technologies currently are React Native and Swift.
                    I live in the Pittsburgh area, and in my free time I enjoy skateboarding, playing guitar, and video games.</p>    
                <a id="resumeLink"
                    href={Resume} 
                    target="_blank" 
                    rel="noopener noreferrer">Resume</a>
                { false && <div className="sliderContainer"> 
                    <Slider {...sliderSettings}>
                        <div>
                            <img alt={""} src={Stella}/>
                        </div>
                        <div>
                            <img alt={""} src={Rocks}/>
                        </div>
                    </Slider>
                </div> }
        </div>
        </div>
        )
    }
}


export default Home