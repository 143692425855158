import React from 'react';
import Menu from './Menu';
import '../css/Contact.css';

export default function Contact(){
    return(
        <div className="contacts">
            <Menu/>
            <h3 id="connect">Connect with me on: </h3>
            <h4><a 
                href="https://github.com/23mwest"
                target="_blank" 
                rel="noopener noreferrer">GitHub</a></h4>
            <h4><a 
                href="https://www.linkedin.com/in/michael-west-911954b8/"
                target="_blank" 
                rel="noopener noreferrer">LinkedIn</a></h4>
            <h4><a 
                href="mailto:23mwest@gmail.com"
                target="_blank" 
                rel="noopener noreferrer">Email</a></h4>
        </div>
    )
}