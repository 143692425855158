import React from 'react';
import { Link } from 'react-router-dom';
import { AwesomeButton } from 'react-awesome-button';
import duck from '../assets/pics/home/duck.png';
import 'react-awesome-button/dist/themes/theme-blue.css';
import '../css/Menu.css';

function Menu() {

    return (
        <div>
            <header>
                <nav>
                    <ul>
                        <h1>Mike West</h1>
                        <div>
                            <img alt={""} src={duck}/>
                        </div>
                    </ul>
                    <ul>
                        <Link class="button" to="/"><AwesomeButton type="primary">Home</AwesomeButton></Link>
                        <Link class="button" to="/projects"><AwesomeButton type="primary">Projects</AwesomeButton></Link>
                        <Link class="button" to="/contact"><AwesomeButton type="primary">Connect</AwesomeButton></Link>
                    </ul>
                </nav>
            </header>
        </div>
    );
}
 
export default Menu;